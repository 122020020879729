import { useRouter } from 'next/compat/router';
import { NextRouter } from 'next/router';
import queryString, { ParsedUrlQuery } from 'querystring';

/**
 * Warning: This assumes rendering within client or a NextJS Server
 * It does not handle usage any other way serverside
 */
const useQueryParams = (): NextRouter['query'] | ParsedUrlQuery => {
  const router = useRouter();

  // SSR Rendering on NextJS Server
  if (router?.query) {
    return router?.query;
  }

  // Client side rendering
  if (typeof window !== 'undefined') {
    // Using deprecated 'querystring' dep for consistency with Next Router
    const searchParams = queryString.parse(window?.location?.search);

    return searchParams || {};
  }

  return {};
};

export default useQueryParams;
