import * as trackAgencyAboutSection from './profile-pages/agency-about-section';
import * as trackAgencyHero from './profile-pages/agency-hero';
import * as trackAgencyRecommendations from './profile-pages/agency-recommendations';
import * as trackAgencyTeamSection from './profile-pages/agency-team-section';
import * as trackContactAboutSection from './profile-pages/contact-about-section';
import * as trackContactAppraisalCta from './profile-pages/contact-appraisal-cta';
import * as trackContactEnquiryForm from './profile-pages/contact-enquiry-form';
import * as trackAgencyEnquiryForm from './profile-pages/agency-enquiry-form';
import * as trackContactHero from './profile-pages/contact-hero';
import * as trackContactRecommendations from './profile-pages/contact-recommendations';
import * as trackCurrentListings from './profile-pages/current-listings';
import * as trackSalesStats from './profile-pages/sales-statistics';
import * as trackProfilePage from './profile-pages/page';
import * as trackLandingPage from './landing-page';

export {
  trackAgencyAboutSection,
  trackAgencyHero,
  trackAgencyRecommendations,
  trackAgencyTeamSection,
  trackContactAboutSection,
  trackContactAppraisalCta,
  trackContactEnquiryForm,
  trackAgencyEnquiryForm,
  trackContactHero,
  trackContactRecommendations,
  trackCurrentListings,
  trackSalesStats,
  trackProfilePage,
  trackLandingPage,
};

export { default as useMixpanel } from './use-mixpanel';
export { getBaseProps, sendToMixpanel } from './mixpanel';
